var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(context){return [_c('vx-card',{staticClass:"mb-base"},[_c('form',[_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ชื่อนิตยสาร")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"ชื่อนิตยสาร","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"name":"title"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("รายละเอียดนิตยสาร")])]),_c('FormContent',[_c('vs-textarea',{staticClass:"w-full",attrs:{"rows":6},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("ระบุช่วงเวลาเริ่มต้น - เวลาสิ้นสุด")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('div',{staticClass:"flex flex-col md:flex-row md:-mx-2"},[_c('div',{staticClass:"w-full mb-2 md:w-1/2 md:px-2"},[_c('ValidationProvider',{attrs:{"name":"ช่วงเวลาเริ่มต้น","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('datepicker',{attrs:{"minimum-view":'month',"maximum-view":'year',"highlighted":{
                      dates: [
                        // Highlight an array of dates
                        _vm.formData.startDate
                          ? new Date(_vm.formData.startDate)
                          : new Date() ],
                    },"disabled-dates":_vm.formData.endDate ? {
                      from: new Date(_vm.formData.endDate)
                    } : {},"format":"MMMM yyyy","placeholder":"ช่วงเวลาเริ่มต้น","required":"","typeable":""},model:{value:(_vm.formData.startDate),callback:function ($$v) {_vm.$set(_vm.formData, "startDate", $$v)},expression:"formData.startDate"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                    "+_vm._s(errors[0])+"\n                  ")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"w-full md:w-1/2 md:px-2"},[_c('ValidationProvider',{attrs:{"name":"เวลาสิ้นสุด","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('datepicker',{attrs:{"minimum-view":'month',"maximum-view":'year',"highlighted":{
                      dates: [
                        // Highlight an array of dates
                        _vm.formData.endDate
                          ? new Date(_vm.formData.endDate)
                          : new Date() ],
                    },"disabled-dates":_vm.formData.startDate ? {
                      to: new Date(_vm.formData.startDate)
                    } : {},"format":"MMMM yyyy","placeholder":"เวลาสิ้นสุด","required":"","typeable":""},model:{value:(_vm.formData.endDate),callback:function ($$v) {_vm.$set(_vm.formData, "endDate", $$v)},expression:"formData.endDate"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                    "+_vm._s(errors[0])+"\n                  ")]):_vm._e()]}}],null,true)})],1)])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เลือกรูปหน้าปก "),_c('br'),_vm._v("(ขนาด 658 x 786 px)")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"รูปปก","rules":"required|lh_mimes:jpg,png,gif|lh_image_resolution:658,786"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('ImageUpload',{attrs:{"folder":(_vm.baseFolder + "/motivo/" + (_vm.formData.folder_name) + "/Cover"),"has-delete-button":"","selection-name":"thumbnail"},model:{value:(_vm.formData.thumbnail),callback:function ($$v) {_vm.$set(_vm.formData, "thumbnail", $$v)},expression:"formData.thumbnail"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("เนื้อหาไฟล์ (PDF)")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('FormContent',[_c('ValidationProvider',{attrs:{"name":"เนื้อหาไฟล์ (PDF)","rules":"required|lh_mimes:pdf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('FileUpload',{attrs:{"folder":(_vm.baseFolder + "/motivo/" + (_vm.formData.folder_name) + "/File"),"has-delete-button":"","selection-name":"document"},model:{value:(_vm.formData.document),callback:function ($$v) {_vm.$set(_vm.formData, "document", $$v)},expression:"formData.document"}}),(errors.length > 0)?_c('span',{staticClass:"text-danger text-sm"},[_vm._v("\n                "+_vm._s(errors[0])+"\n              ")]):_vm._e()]}}],null,true)})],1)],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("สถานะ")])]),_c('FormContent',[_c('vs-checkbox',{model:{value:(_vm.formData.is_new),callback:function ($$v) {_vm.$set(_vm.formData, "is_new", $$v)},expression:"formData.is_new"}},[_vm._v("New")])],1)],1),_c('vs-divider'),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดโดย")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formData.updated_by))])])],1),_c('FormLayout',[_c('FormLabel',[_c('span',[_vm._v("แก้ไขล่าสุดวันที่")])]),_c('div',{staticClass:"flex items-center vx-col w-full md:w-6/12"},[_c('span',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.formData.updated_at)))])])],1),_c('FormLayout',{staticClass:"mt-8"},[_c('FormLabel'),_c('FormContent',[_c('vs-button',{on:{"click":function($event){return _vm.submit(context)}}},[_vm._v("\n              "+_vm._s(_vm.formData.id ? 'Update' : 'Submit')+"\n            ")])],1)],1)],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }