<template>
  <div>
    <ValidationObserver v-slot="context">
      <vx-card class="mb-base">
        <form>
          <FormLayout>
            <FormLabel>
              <span>ชื่อนิตยสาร</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="ชื่อนิตยสาร"
                rules="required"
              >
                <vs-input 
                  v-model="formData.title" 
                  class="w-full" 
                  name="title" />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>รายละเอียดนิตยสาร</span>
            </FormLabel>
            <FormContent>
              <vs-textarea 
                v-model="formData.description" 
                :rows="6"
                class="w-full" />
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>ระบุช่วงเวลาเริ่มต้น - เวลาสิ้นสุด</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <div class="flex flex-col md:flex-row md:-mx-2">
                <div class="w-full mb-2 md:w-1/2 md:px-2">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="ช่วงเวลาเริ่มต้น"
                    rules="required"
                  >
                    <datepicker
                      v-model="formData.startDate"
                      :minimum-view="'month'"
                      :maximum-view="'year'"
                      :highlighted="{
                        dates: [
                          // Highlight an array of dates
                          formData.startDate
                            ? new Date(formData.startDate)
                            : new Date(),
                        ],
                      }"
                      :disabled-dates="formData.endDate ? {
                        from: new Date(formData.endDate)
                      } : {}"
                      format="MMMM yyyy"
                      placeholder="ช่วงเวลาเริ่มต้น"
                      required
                      typeable
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
                <div class="w-full md:w-1/2 md:px-2">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="เวลาสิ้นสุด"
                    rules="required"
                  >
                    <datepicker
                      v-model="formData.endDate"
                      :minimum-view="'month'"
                      :maximum-view="'year'"
                      :highlighted="{
                        dates: [
                          // Highlight an array of dates
                          formData.endDate
                            ? new Date(formData.endDate)
                            : new Date(),
                        ],
                      }"
                      :disabled-dates="formData.startDate ? {
                        to: new Date(formData.startDate)
                      } : {}"
                      format="MMMM yyyy"
                      placeholder="เวลาสิ้นสุด"
                      required
                      typeable
                    />
                    <span 
                      v-if="errors.length > 0" 
                      class="text-danger text-sm">
                      {{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>เลือกรูปหน้าปก <br>(ขนาด 658 x 786 px)</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="รูปปก"
                rules="required|lh_mimes:jpg,png,gif|lh_image_resolution:658,786"
              >
                <ImageUpload
                  :folder="`${baseFolder}/motivo/${formData.folder_name}/Cover`"
                  v-model="formData.thumbnail"
                  has-delete-button
                  selection-name="thumbnail"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>เนื้อหาไฟล์ (PDF)</span>
              <span class="text-danger"> *</span>
            </FormLabel>
            <FormContent>
              <ValidationProvider
                v-slot="{ errors }"
                name="เนื้อหาไฟล์ (PDF)"
                rules="required|lh_mimes:pdf"
              >
                <FileUpload
                  :folder="`${baseFolder}/motivo/${formData.folder_name}/File`"
                  v-model="formData.document"
                  has-delete-button
                  selection-name="document"
                />
                <span 
                  v-if="errors.length > 0" 
                  class="text-danger text-sm">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </FormContent>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>สถานะ</span>
            </FormLabel>
            <FormContent>
              <vs-checkbox v-model="formData.is_new">New</vs-checkbox>
            </FormContent>
          </FormLayout>
          <vs-divider />
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดโดย</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formData.updated_by}}</span>
            </div>
          </FormLayout>
          <FormLayout>
            <FormLabel>
              <span>แก้ไขล่าสุดวันที่</span>
            </FormLabel>
            <div class="flex items-center vx-col w-full md:w-6/12">
              <span class="mt-2"> {{formatDate(formData.updated_at)}}</span>
            </div>
          </FormLayout>
          <FormLayout class="mt-8">
            <FormLabel/>
            <FormContent>
              <vs-button 
                @click="submit(context)">
                {{ formData.id ? 'Update' : 'Submit' }}
              </vs-button>
            </FormContent>
          </FormLayout>
        </form>
      </vx-card>
    </ValidationObserver>
  </div>
</template>

<script>
import { onMounted, computed } from '@vue/composition-api'
import Datepicker from 'vuejs-datepicker'
import FileUpload from '@/components/upload/FileUpload'
import ImageUpload from '@/components/upload/ImageUpload'
import useMagazineUtil from '@/use/useMagazineUtil'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import env from '@/env'

export default {
  components: {
    FileUpload,
    ImageUpload,
    Datepicker,
  },
  setup(props, ctx) {
    const { formData, fetchByRouteId } = useMagazineUtil(ctx)
    const { moment } = useDatepicker()
    const crudForm = useCrud(ctx, 'magazine')
    crudForm.routePrefix.value = 'lh-content-motivo'
    onMounted(() => {
      fetchByRouteId()
    })

    const submit = (ctx) => {
      crudForm.addItem(ctx, formData)
    }

    const baseFolder = computed(() => env.VUE_APP_CLOUDINARY_DEFAULT_FOLDER)


    const disabledDateLessThanStartDate = (date) => {
      return moment(date).isBefore(formData.startDate)
    }

    return {
      ...crudForm,
      formData,
      submit,
      useDatepicker: useDatepicker(),
      baseFolder,
      disabledDateLessThanStartDate
    }
  },
}
</script>
